<template>
    <el-card shadow="never">
        <template #header>
            <div class="clearfix" style="text-align:left">
                <span>账号角色</span>
            </div>
        </template>
        <div class="content">
            <el-select placeholder="角色所属" v-model="type" size="small" style="width:100px;margin-right:10px;"
                @change="GetRoleList(type)">
                <el-option label="运维端" value="1"></el-option>
                <el-option label="商户端" value="2"></el-option>
                <el-option label="代理商" value="3"></el-option>
                <el-option label="分销商" value="4"></el-option>
            </el-select>
            <el-button @click="CreateRole" type="primary" size="small">创建角色</el-button>
        </div>
        <div class="content-main">
            <el-table :data="tabledata" border style="width: 100%; margin: 1em 0" v-loading="load"
                element-loading-spinner="el-icon-loading" element-loading-text="加载中..." size="small">
                <el-table-column type="index" width="80" label="序号"></el-table-column>
                <el-table-column prop="Role" label="账号角色" width="100px;"></el-table-column>
                <el-table-column prop="Type" label="角色所属" width="80px;">

                    <template #default="scope">
                        <span v-if="scope.row.Type == 1">运维端</span>
                        <span v-if="scope.row.Type == 2">商户端</span>
                    </template>
                </el-table-column>
                <el-table-column prop="PermissionName" label="账号权限"></el-table-column>
                <el-table-column prop="AddDTime" label="创建时间" :formatter="Getdate" width="200px;"></el-table-column>
                <el-table-column prop="UpdateDTime" label="更新时间" :formatter="Getdate" width="200px;"></el-table-column>
                <el-table-column label="操作" width="150px;">

                    <template #default="scope">
                        <el-button type="text" @click="Edit(scope.row.ID)">编辑</el-button>
                        <el-button type="text" @click="Del(scope.row.ID)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </el-card>
    <el-dialog v-model="edit" title="编辑" center>
        <el-form :model="accountinfo" label-width="150px" ref="accountinfo">
            <el-form-item label="角色名称" prop="Role" :rules="{ required: true, message: '请填写角色名称', trigger: 'blur' }">
                <el-input v-model="accountinfo.Role"></el-input>
            </el-form-item>
            <el-form-item label="角色所属" prop='Type'>
                <el-radio-group v-model="accountinfo.Type">
                    <el-radio-button label="1">运维端</el-radio-button>
                    <el-radio-button label="2">商户端</el-radio-button>
                    <el-radio-button label="3">代理商</el-radio-button>
                    <el-radio-button label="4">分销商</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="权限设置" :rules="{ required: true, message: '请勾选权限', trigger: 'blur' }">
                <!--<el-checkbox-group v-model="accountinfo.permissions">
                     <el-checkbox v-for="permit in permission" :key="permit" :label="permit.ID">
                        {{permit.Permission}}
                    </el-checkbox> 
                </el-checkbox-group>-->
                <!-- <el-input v-model="filterText" style="width: 240px" placeholder="请输入关键词" /> -->
                <el-tree ref="treeRef" show-checkbox style="max-width: 600px" :default-checked-keys="checked"
                    class="filter-tree" :data="data" :props="props" />
            </el-form-item>
        </el-form>

        <template #footer>
            <el-button type="primary" @click="SaveRole">确定</el-button>
            <el-button @click="CloseEdit">取消</el-button>
        </template>
    </el-dialog>
</template>

<style>
.el-card {
    padding: 0 !important;
}

.el-card__body {
    padding: 10px;
}

.el-card__header {
    padding: 10px !important;
}
</style>

<script>
import constant from "@/constant"
import { useRouter } from "vue-router";
import { reactive, ref } from "vue"

export default {
    data() {
        return {
            type: '1',
            tabledata: [],
            edit: false,
            permission: "",
            accountinfo: {
                Role: '',
                id: '',
                permissions: [],
                Type: 1,
            },
            data: [],
            filterText: ref(''),
            checked: [1, 2, 3],
            props: {
                label: 'name',
                children: 'children',
                disabled: 'disabled'
            },
            useRouter: [],
            roleids: reactive([])
        }
    },
    methods: {
        Edit(id) {
            this.checked = []
            if (id) {
                this.axios.get(constant.get_role, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        id: id,
                    }
                }).then((response) => {
                    console.log(response.data);
                    this.accountinfo.id = response.data.ID
                    this.accountinfo.Role = response.data.Role;
                    if (response.data.Permission == '') {
                        this.accountinfo.permissions = []
                    } else {
                        let concat = this.checked.concat(response.data.Permission)
                        this.checked = concat
                        console.log(this.checked)
                        this.accountinfo.permissions = response.data.Permission;
                    }
                    this.accountinfo.Type = response.data.Type;
                });
            }
            this.edit = true;
        },
        CreateRole() {
            this.edit = true;
        },
        SaveRole() {
            console.log(this.$refs.treeRef.getCheckedNodes());
            let tree = this.$refs.treeRef.getCheckedNodes()
            for (let index = 0; index < tree.length; index++) {
                console.log(tree[index].id)
                this.roleids.push(tree[index].id)
            }
            console.log(this.roleids)
            if (this.roleids.length <= 0) {
                this.$message.error("请选择角色权限");
                return false;
            }
            this.$refs['accountinfo'].validate((valid) => {
                if (valid) {
                    this.accountinfo.permissions = this.roleids
                    this.axios.post(constant.save_role, this.accountinfo, {
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                    }).then((response) => {
                        if (response.data == 'OK') {
                            this.$message.success("操作成功");
                            this.edit = false;
                            this.$router.go(0);
                        } else {
                            this.$message.error(response.data);
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        Getdate(row, column) {
            var cur_date = row[column.property];
            return formatDate(cur_date);
            function formatDate(date) {
                var datetime = new Date(date * 1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                var hour = ("0" + datetime.getHours()).slice(-2);
                var minute = ("0" + datetime.getMinutes()).slice(-2);
                var second = ("0" + datetime.getSeconds()).slice(-2);
                return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
            }
        },
        CloseEdit() {
            this.edit = false;
        },
        GetRoleList(type) {
            this.axios.get(constant.get_role_list, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    type: type,
                }
            }).then((response) => {
                console.log(response.data);
                this.tabledata = response.data;
            });
        },
        Del(id) {
            this.$confirm("删除该角色时，对应角色的工作人员账号将失效，确认执行该操作么？", "提示", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.axios.get(constant.delrole, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params: {
                        id: id
                    }
                }).then((response) => {
                    console.log(response.data);
                    if (response.data == "OK") {
                        this.$message.success("操作成功");
                        this.$router.go(0);
                    }
                });
            }).catch(() => {
                return false;
            });
        }
    },
    created: function () {
        const router = useRouter();
        let array = router.options.routes
        console.log(array)
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            console.log(element)
            if (element.path == '/' || element.path == '/main') {
                element.disabled = true
                let children = Object.keys(element)  // ['name', 'sex']
                if (children.includes('children')) {
                    element.children[0].disabled = true
                }
            }
        }
        this.data = array
        this.axios.get(constant.get_permission, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            console.log(response.data);
            this.permission = response.data;
        });
        this.axios.get(constant.get_role_list, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            console.log(response.data);
            this.tabledata = response.data;
        });
    },
}
</script>